import Vue from "vue";

/*TITLE*/
document.title = "Residencial Ocean Florida | Porcelanosa PARTNERS";
/*PROJECT TYPE*/
Vue.prototype.$typeProject = "PARTNERS";

/*HERO*/
Vue.prototype.$title = "Residencial Ocean Florida";
Vue.prototype.$subtitle = "Viviendas unifamiliares exclusivas en Huelva Capital";

/*INTRO*/
Vue.prototype.$promoter = "Residencial Ocean Florida";
Vue.prototype.$introSubtitle = "Viviendas unifamiliares exclusivas en Huelva Capital";

/*GALLERY*/
Vue.prototype.$image_sittingroom = "salon-opc.-3-forest-cognac--20230127100153.jpg";
Vue.prototype.$image_kitchen = "cocina-pavimento-vela-natural-frentes-roble-pardo-encimera-xtone-bottega-caliza--20230127100124.jpg";
Vue.prototype.$image_bath1 = "banop-opc.-4-pavimento-forest-arce-revestimiento-base-vela-smoked-revestimiento-decorativo-xtone-liem-grey-nature--20230127100141.jpg";
Vue.prototype.$image_bath2 = "aseo-pavimento-vela-natural-revestimiento-base-vela-natural-revestimiento-deco-limit-vela-natural--20230127100121.jpg";
Vue.prototype.$image_room = "";
Vue.prototype.$image_facade = "";

Vue.prototype.$gallery_images_sittingroom = [
  {
    src: "salon-opc.-3-forest-cognac--20230127100153.jpg",
  },
  {
    src: "salon-opc.-4-forest-arce--20230127100101.jpg",
  },
  {
    src: "salon-opc.-2-vela-smoked--20230127100108.jpg",
  },
  {
    src: "salon-opc.-1-vela-natural--20230127100115.jpg",
  },
];

Vue.prototype.$gallery_images_kitchen = [
  {
    src: "cocina-pavimento-vela-natural-frentes-roble-pardo-encimera-xtone-bottega-caliza--20230127100124.jpg",
  },
  {
    src: "cocina-pavimento-vela-natural-frentes-eucalyptus-blond-encimera-xtone-liem-grey--20230127100133.jpg",
  },
  {
    src: "cocina-pavimento-vela-smoked-frentes-roble-organico-encimera-xtone-bottega-acero--20230127100144.jpg",
  },
  {
    src: "cocina-pavimento-forest-arce-frentes-bison-encimera-xtone-calacata-gold--20230127100157.jpg",
  },
  {
    src: "cocina-pavimento-forest-cognac-frentes-blanco-nubol-encimera-krion-klife--20230127100108.jpg",
  },
];

Vue.prototype.$gallery_images_bath1 = [
  {
    src: "banop-opc.-4-pavimento-forest-arce-revestimiento-base-vela-smoked-revestimiento-decorativo-xtone-liem-grey-nature--20230127100141.jpg",
  },
  {
    src: "banop-opc.-3-pavimento-forest-cognac-revestimiento-base-vela-natural-revestimiento-decorativo-xtone-calacata-gold-nature--20230127100147.jpg",
  },
  {
    src: "banop-opc.-2-pavimento-vela-smoked-revestimiento-base-vela-smoked-revestimiento-decorativo-xtone-liem-grey-nature--20230127100157.jpg",
  },
  {
    src: "banop-opc.-1-pavimento-vela-natural-revestimiento-base-vela-natural-revestimiento-decorativo-xtone-calacata-gold-nature--20230127100103.jpg",
  },
];

Vue.prototype.$gallery_images_bath2 = [
  {
    src: "aseo-pavimento-vela-natural-revestimiento-base-vela-natural-revestimiento-deco-limit-vela-natural--20230127100121.jpg",
  },
  {
    src: "aseo-pavimento-vela-smoked-revestimiento-base-vela-natural-revestimiento-deco-limit-vela-natural--20230127100132.jpg",
  },
  {
    src: "aseo-pavimento-forest-arce-revestimiento-base-vela-natural--20230127100142.jpg",
  },
  {
    src: "aseo-pavimento-forest-cognac-revestimiento-base-vela-natural--20230127100152.jpg",
  },
  {
    src: "banos-banera-opc.-1-pavimento-forest-cognac-revestimiento-base-vela-natural--20230127100101.jpg",
  },
];

Vue.prototype.$gallery_images_room = [
];

Vue.prototype.$gallery_images_facade = [
];

/*GALLERY GRID*/
Vue.prototype.$gallery_grid_sittingroom = "6";
Vue.prototype.$gallery_grid_kitchen = "6";
Vue.prototype.$gallery_grid_bath1 = "3";
Vue.prototype.$gallery_grid_bath2 = "3";
Vue.prototype.$gallery_grid_room = "12";
Vue.prototype.$gallery_grid_facade = "12";

/*TOUR VIRTUAL*/
Vue.prototype.$360 = "https://360.porcelanosapartners.com/OCEAN_FLORIDA/";

/*3D FILM*/
Vue.prototype.$3dFilm = "https://www.youtube.com/embed/2-YUZRpEXsQ";

/*CATALOG*/
Vue.prototype.$catalog = true;

/*SPACES*/
Vue.prototype.$spaces = "";

/*PROMOTION LOGO*/
Vue.prototype.$promotion_logo = true;

/*PROMOTER LOGO*/
Vue.prototype.$promoter_logo = true;

/*CONTACT*/
Vue.prototype.$promoter_address = [
  {
    icon: "mdi-map-marker",
    link: "https://maps.google.com/?q=avenida%20pablo%20ruiz%20picasso%20junto%20al%20parque%20antonio%20machado%20–%20huelva%20capital",
    text: "Avenida Pablo Ruiz Picasso, junto al Parque Antonio Machado – Huelva Capital",
  },
];

Vue.prototype.$showroom = [
];

Vue.prototype.$contact = [
  {
    icon: "mdi-phone",
    link: "telf:954902056",
    text: "954902056",
  },
  {
    icon: "mdi-email",
    link: "mailto:info@grupoabu.es",
    text: "info@grupoabu.es",
  },
];
